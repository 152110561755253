/* custom CC CSS */

.cc-modal {
    width: 100%;
    max-width: 900px;
    border: none;
    outline: none;
    border-radius: 0px;
    box-shadow: none;
    height: 560px;
    top: 0px;
    --tw-translate-y: 0% !important;
}

.cc-modal-close {
    display: none;
}

.cc-modal-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.cc-modal-overlay {
    background-color: white;
}

.cc-modal .cc-flex-col>button.cc-flex:nth-of-type(2) {
    display: none;
}

.cc-modal> :first-child {
    width: 100%;
}